import { createSelector } from 'reselect';

import { RootState } from '../RootReducer';
import { AuthState } from './authSlice';

const getAuthState = (rootState: RootState): AuthState => rootState.authReducer;

export const getIdToken = createSelector(getAuthState, (s) => s.idToken);

export const getAccessToken = createSelector(
  getAuthState,
  (s) => s.accessToken
);
