import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LSTokens } from 'helpers/authorization';

const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  idToken: string | null;
  accessToken: string | null;
}

const initialAuthState: AuthState = {
  idToken: null,
  accessToken: null,
};

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {
    setTokens: (state, action: PayloadAction<LSTokens>) => {
      const { idToken, accessToken } = action.payload;
      state.accessToken = accessToken;
      state.idToken = idToken;
    },
    logout: (state) => {
      state.accessToken = initialAuthState.accessToken;
      state.idToken = initialAuthState.idToken;
    },
  },
});

export const { setTokens, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
