import React from 'react';

import css from './errorComponent.module.scss';

export const ErrorComponent: React.FC = () => {
  return (
    <div className={css.root}>
      <div className={css.title}>404</div>
    </div>
  );
};
