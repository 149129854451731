import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

import { ErrorComponent } from 'components/ErrorComponent';
import { PrivateRoutes } from 'components/PrivateRoutes';

const TagsHistoryPage = loadable(() => import('pages/TagsHistory'), {
  resolveComponent: (component) => component.TagsHistoryPage,
});

const GenerateTagsPage = loadable(() => import('pages/GenerateTags'), {
  resolveComponent: (component) => component.GenerateTags,
});

const AuthorizationPage = loadable(() => import('pages/Authorization'), {
  resolveComponent: (component) => component.Authorization,
});

export const Router = () => {
  return (
    <Routes>
      <Route element={<AuthorizationPage />} path="/callback" />
      <Route element={<PrivateRoutes />}>
        <Route element={<TagsHistoryPage />} path="/" index />
        <Route element={<GenerateTagsPage />} path="/generate-tags" />

        <Route path="*" element={<ErrorComponent />} />
      </Route>
    </Routes>
  );
};
