import React, { PropsWithChildren } from 'react';
import { useMatch } from 'react-router-dom';

import { MainMenu } from 'components/MainMenu';

import css from './mainLayout.module.scss';

export const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthorizationPage = useMatch('/callback');

  return (
    <main className={css.root}>
      {!isAuthorizationPage && (
        <aside className={css.aside}>
          <MainMenu />
        </aside>
      )}
      <div className={css.mainContainer}>{children}</div>
    </main>
  );
};
