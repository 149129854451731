import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { ReactComponent as GenerateIcon } from 'assets/menu/generate.svg';
import { ReactComponent as HelpIcon } from 'assets/menu/helps.svg';
import { ReactComponent as HistoryIcon } from 'assets/menu/history.svg';
import { ReactComponent as NotificationsIcon } from 'assets/menu/notification.svg';

import css from './mainMenu.module.scss';

const menu = [
  {
    id: 1,
    path: '/',
    text: 'Tags History',
    icon: <HistoryIcon className={css.historyIcon} />,
  },
  {
    id: 2,
    path: '/generate-tags',
    text: 'Generate Tags',
    icon: <GenerateIcon className={css.generateIcon} />,
  },
  {
    id: 3,
    path: '/notifications',
    text: 'Notifications',
    icon: <NotificationsIcon className={css.notificationIcon} />,
  },
  {
    id: 4,
    path: '/help',
    text: 'Help',
    icon: <HelpIcon className={css.helpIcon} />,
  },
];

export const MainMenu: React.FC = () => {
  return (
    <div className={css.root}>
      <div className={css.logoWrapper}>
        <NavLink to="/">
          <LogoIcon className={css.logo} />
        </NavLink>
      </div>
      <nav className={css.menu}>
        <ul className={css.menuList}>
          {menu.map((item) => (
            <li className={css.menuItem} key={item.id}>
              <NavLink
                className={({ isActive }) =>
                  cx(css.menuItemLink, {
                    [css.menuItemLinkActive]: isActive,
                  })
                }
                to={item.path}
              >
                {item.icon}
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
