import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';

import { MainLayout } from 'layouts/MainLayout';

import { GlobalErrorBoundary } from './GlobalErrorBoundary';
import { Router } from './Router';

import 'styles/main.scss';

function App() {
  return (
    <Provider store={store}>
      <GlobalErrorBoundary>
        <Suspense fallback={<>Loading... </>}>
          <BrowserRouter>
            <MainLayout>
              <Router />
            </MainLayout>
          </BrowserRouter>
        </Suspense>
      </GlobalErrorBoundary>
    </Provider>
  );
}

export default App;
