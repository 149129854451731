// import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate, Outlet } from 'react-router-dom';

// import { getAccessToken, getIdToken } from 'store/auth';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const PrivateRoutes = () => {
  // const idToken = useSelector(getIdToken);
  // const accessToken = useSelector(getAccessToken);
  const token = true;

  // useEffect(() => {
  //   if (!idToken && !accessToken) {
  //     window.location.href =
  //       'https://digit-clone-example-273312959678.auth.eu-central-1.amazoncognito.com/login?client_id=q7mcrnu2r37st75ff0klnt7aa&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback%2F';
  //   }
  // }, [idToken, accessToken]);

  if (token) {
    return <Outlet />;
  }

  return null;
};
