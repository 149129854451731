import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { rootReducer, RootState } from './RootReducer';

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>;
